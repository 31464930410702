import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from 'gatsby-plugin-image';

const NotFoundPage = () => (
  <Layout>
      <div className="container">
          <div className="not-found">
              <h1>404: Not Found</h1>
              <StaticImage
                  className='not-found__image'
                  src='../images/404.png'
                  loading='eager'
                  width={600}
                  quality={95}
                  formats={[ 'auto', 'webp' ]}
                  alt='404 not found'
              />
              <p>Nie znaleziono wybranej storny.</p>
          </div>
      </div>
  </Layout>
)

export const Head = ({location}) => <Seo title="404: Not Found" location={location} />

export default NotFoundPage
